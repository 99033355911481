export default {
  // Moment integration
  "Timestamp Date": "{{date, date}}",
  "Change Language": "Change Language",
  "Enter an Address, Transaction Hash or Block Number":
    "🔎 Enter an Address, Transaction Hash or Block Number",
  "JSON-RPC API Documentation": "JSON-RPC API Documentation",
  "Toggle Dark Mode": "Toggle Dark Mode",
  "Block Height": "Block Height",
  "Chain ID": "Chain ID",
  Syncing: "Syncing",
  "Gas Price": "Gas Price",
  "Network Hash Rate": "Network Hash Rate",
  Peers: "Peers",
  "Hash Rate": "Hash Rate",
  "Transaction Count": "Transaction Count",
  "Gas Usage": "Gas Usage",
  Uncles: "Uncles",
  "Top Miners": "Top Miners",
  "Top Miners by extraData": "Top Miners by extraData",
  "Top Miners by Address": "Top Miners by Address",
  "Block Number": "Block Number",
  Hash: "Hash",
  Timestamp: "Timestamp",
  Transactions: "Transactions",
  Number: "Number",
  ParentHash: "ParentHash",
  Miner: "Miner",
  Nonce: "Nonce",
  Difficulty: "Difficulty",
  "Extra Data": "Extra Data",
  "State Root": "State Root",
  "Transaction Root": "Transaction Root",
  "Receipts Root": "Receipts Root",
  Block: "Block",
  "Gas Used": "Gas Used",
  "Cumulative Gas Used": "Cumulative Gas Used",
  "Value in Ether": "Value in Ether",
  From: "From",
  To: "To",
  "Contract Address": "Contract Address",
  "Transaction Index": "Transaction Index",
  "Receipt Status": "Receipt Status",
  "Receipt Logs": "Receipt Logs",
  "No Transactions for this block range.":
    "No Transactions for this block range.",
  Address: "Address",
  Balance: "Balance",
  Code: "Code",
  Configuration: "Configuration",
  Back: "Back",
  "Ethereum RPC": "Ethereum RPC",
  "Service Runner RPC": "Service Runner RPC",
};
